<template>
  <div style="text-align: justify">
    <h2 class="mb-4">Determining the Formula of a Hydrate - Prelab Exercise</h2>

    <p class="mb-6">
      This pre-lab is an example of a typical calculation for determining the stoichiometry of a
      hydrate. Using the data below, determine the stoichiometry of the hydrated calcium sulfate,
      <chemical-latex content="CaSO4 * x H2O" />
    </p>

    <p class="mb-6">
      <span class="text-bold">Please Note:</span> Although <stemble-latex content="$x$" /> will
      normally be an integer, in this theoretical calculation, you should report the value of
      <stemble-latex content="$x$" />
      to the correct number of significant figures. Please see the Rules for Significant Figures in
      your lab manuals for more information regarding sig figs.
    </p>

    <p class="mb-2">
      Please enter your exercise number below. Without this number, you will automatically receive a
      grade of 0 on your prelab. If this does not match the number on the prelab you hand in, you
      will receive a grade of 0.
    </p>

    <calculation-input
      v-model="inputs.exerciseNumber"
      class="mb-5"
      prepend-text="$\text{Exercise Number:}$"
      dense
      :disabled="!allowEditing"
    />

    <p class="mb-1"><u>Data</u>:</p>

    <v-simple-table style="margin-left: 5px; margin-bottom: 20px; max-width: 513px">
      <thead>
        <tr>
          <td>Mass of Crucible</td>
          <td v-if="exNumber >= 101 && exNumber <= 700">
            <latex-number :number="crucible.toFixed(4)" unit="\text{g}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>

        <tr>
          <td>Mass of Crucible + Gypsum</td>
          <td v-if="exNumber >= 101 && exNumber <= 700">
            <latex-number :number="crucibleGypsum" unit="\text{g}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>

        <tr>
          <td>Mass of crucible + contents after heating</td>
          <td v-if="exNumber >= 101 && exNumber <= 700">
            <latex-number :number="crucibleCalciumSulfate" unit="\text{g}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
      </thead>
    </v-simple-table>

    <p class="mb-3">
      What is the value of <stemble-latex content="$x$" /> for this sample of gypsum?
    </p>

    <calculation-input v-model="inputs.xAns" prepend-text="$x:$" dense :disabled="!allowEditing" />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';
import ChemicalLatex from '../displayers/ChemicalLatex';
import {seededRandomBetween} from '@/courses/utils/seededShuffle';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'UPEIGypsumPreLabV2',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        exerciseNumber: null,
        xAns: null,
      },
    };
  },
  computed: {
    exNumber() {
      if (this.inputs.exerciseNumber) {
        return this.inputs.exerciseNumber;
      } else {
        return 0;
      }
    },
    crucible() {
      return seededRandomBetween(this.exNumber, 14, 15, 4);
    },
    calciumSulfate() {
      return seededRandomBetween(this.exNumber * 2, 3, 6, 4);
    },
    xValue() {
      return seededRandomBetween(this.exNumber * 3, 3.6, 4.4, 3);
    },
    crucibleCalciumSulfate() {
      return (this.crucible + this.calciumSulfate / 3).toFixed(4);
    }, // Note that calciumSulfate value was scaled by factor of 3
    crucibleGypsum() {
      return (
        this.crucible +
        this.calciumSulfate / 3 +
        (((this.calciumSulfate / 3 / 136.15) * this.xValue) / 2) * 18.01528
      ).toFixed(4);
    }, // Note that xValue was scaled by a factor of 2
  },
};
</script>
